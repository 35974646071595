const WebFont = require("webfontloader")

/* global window, document */

export const onClientEntry = () => {
  WebFont.load({
    custom: {
      families: ["Raleway", "Muli", "Rubik"],
    },
    // eslint-disable-next-line object-shorthand
    active: function() {
      document.dispatchEvent(new Event("FontsLoaded"))
    },
  })
}

/* global window, document */
const scrollTo = id => () => {
  const el = document.querySelector(id)
  if (el) {
    const yOffset = -70
    const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset
    window.scrollTo({ top: y, behavior: "smooth" })
    return true
  }
  return false
}

export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    window.setTimeout(scrollTo(hash), 10)
  }
}
