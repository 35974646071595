import theme from "@chakra-ui/theme"

const breakpoints = ["30em", "48em", "62em", "67em", "74em", "80em"]
// aliases
breakpoints.sm = breakpoints[0]
breakpoints.md = breakpoints[1]
breakpoints.lg = breakpoints[2]
breakpoints.lg2 = breakpoints[3]
breakpoints.calc = breakpoints[4]
breakpoints.xl = breakpoints[5]

export default {
  ...theme,
  fonts: {
    ...theme.fonts,
    body: "Muli, sans-serif",
    heading: "Raleway, serif",
    mono: "Muli, monospace",
  },
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2xl": "24px",
    "3xl": "30px",
    "4xl": "36px",
    "5xl": "48px",
    "6xl": "60px",
    "7xl": "72px",
  },
  zIndices: {
    hide: -1,
    auto: "auto",
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
  },
  colors: {
    ...theme.colors,
    gray: {
      ...theme.colors.gray,
      50: "#F8F9FA",
      100: "#F1F3F5",
      200: "#E8ECEF",
      300: "#DDE1E6",
      400: "#CED4DA",
      500: "#ABB5BD",
      600: "#858E97",
      650: "#6c7684", //exception, need to test if this can replace color above
      700: "#484F58",
      800: "#333A41",
      900: "#21242A",
    },
  },
  breakpoints,
}
